// Basically here we will manage how to select the days and such.

<template>
    <div class="calendar_mod">
        <h4 class="card-title title">
            Pick a date
        </h4>
       <DatePicker :is-expanded='true' :min-date=initial_date
       :modelValue=selectedDate color='green'
       :disabled-dates={} :available-dates=calendar_ready_dates
       v-model='selectedDate' :model_config=modelConfig
       @update:to-page="searchMonthData"
       ref='datepicker'
       />    
        <!-- <h4>
            Selected date: {{selectedDate}}
        </h4> -->
    </div>

</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import {Calendar, DatePicker} from 'v-calendar';
import 'v-calendar/dist/style.css'

export default {
    name:"EventCalendar",
    props:{},
    components:{
        Calendar,
        DatePicker

    },
    methods:{
        ...mapActions(['selectDate','SearchEventMonthAvailability']),
        async searchMonthData(obj){
            let current_month = this.initial_date.getMonth()
            let calculated_range = obj.month - current_month;
            if( calculated_range <= 7){
                await this.$store.dispatch('SearchEventMonthAvailability', obj);
                if (this.selectDate.length>0){
                    // console.log()
                    // console.log(this.selectDate)
                    this.selectDate.prototype.set(this.initial_date)
                    // this.$refs.datepicker.input(this.selectDate);
                }

            }
            else{
                this.$toast.add({severity:'error',
                                 summary:'Schedule error',
                                 detail:"You can only book within a range of 7 months from the current date.",
                                         life:7000})
                }
            

        },
    },
    computed:{
        ...mapGetters(['date_selected','calendar_ready_dates']),
        selectedDate:{
            get(){
                let return_value = this.date_selected
                if(!return_value){
                    // return_value = this.initial_date.toJSON().replace('Z', '')
                    return_value = this.initial_date
                }
                return return_value
            },
            set(value){
                if (!value){
                    value = this.initial_date;
                }
                let final_selected = value.toJSON().replace('Z', '')
                // this.$store.dispatch('selectDate', final_selected)
                this.$store.dispatch('selectDate', value)

            }
        }
    },
    data(){
        return{
            initial_date: new Date(),
            modelConfig:{
                type: 'string',
                mask: 'iso'
            }
        }
    },
}
</script>

<style scoped>
    .title{
        text-align: left;
        margin-bottom:15px;
        margin-top:10px;

    }
    .calendar_mod{
	width: 85%;
	margin: 0px 0px 0px 49px;
}
</style>