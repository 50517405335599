/*
this is going to be the container to gather the components in order to make sure
the selection of the booking is right.
this contains: 
- NumberPeople
- Calendar 
- List available bookings.
*/
<template>
    <div class="main_component_holder">

         <div class="people_selector">
         <NumberPeople :maxPeopleCount="selected_event.bookingLimits[0].max"
                :minPeopleCount="selected_event.bookingLimits[0].min"
                :title="title"/>
        </div>
        <div class="info_people alert alert-info">
            <p>
                <i class="fa-solid fa-circle-info"></i>
                if you have kids below 2 years, there's no need to
                add them as a participant.
            </p>
        </div>
        <div class="info_people alert alert-info" v-if="isCollaborator">
            <p>
                <i class="fa-solid fa-circle-info"></i>
                As a collaborator, you enter for free and have 2 monthly slots at
                a preferential price (US ${{ event_configuration.event_configuration.guest_configuration.Price }}.00).
                After using these slots, the regular price of (US ${{selected_event.defaultRates[0].price.amount}}.00 ) will apply.
            </p>
        </div>
        <div v-if="event_configuration.event_configuration !== undefined &&
        event_configuration.event_configuration.property_configuration.slots"
        style="padding: 3rem;">
                <h4 style="text-align: left;">Slots available</h4>
                <div class="card p-6" style="max-width: fit-content; max-height: 50px; text-align: center;">
                    <div class="card-body">
                        <p>{{event_configuration.event_configuration.collaborator_slot.available_slots}}</p>
                    </div>
                </div>
        </div>
        <div v-if="isCollaborator" class="m-4">
            <h4>Are you going to be assisting?</h4>
            <div style="text-align: left;">
                <label for="one" class="radio_options">
                    <input type="radio" id="one" :value="true" v-model="participatingValue" />
                    Yes
                </label>
                <label for="two" class="radio_options">
                    <input type="radio" id="two" :value="false" v-model="participatingValue" />
                    No
                </label>
            </div>
        </div>
        <div class="calendar_holder">
            <EventCalendar />
        </div>
        <div class="available_spots">
            <AvailableEventList />
        </div>
    </div>
</template>
<script>
import NumberPeople from './NumberPeople.vue'
import EventCalendar from './Calendar.vue'
import AvailableEventList from './AvailableEventList.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
    name:'SideBookingBar',
    props:{
        selected_event: {
            type:Object
        }
    },
    // computed:
    components:{
        NumberPeople,
        EventCalendar,
        AvailableEventList
    },
    data(){
        return{
            title: "Select number of participants"
        }
    },
    methods:{
        ...mapActions['searchAvailiability'],
    },
    computed:{
        ...mapGetters(['event_configuration', 'event_selected', 'collaborator_included', 'isCollaborator']),
        participatingValue: {
            get() {
                return this.collaborator_included;
            },
            set(value) {
                this.$store.dispatch('updateCollaboratorParticipation', value);
                this.$store.dispatch('searchAvailiability')
            }
        }
    }
}
</script>
<style scoped>
.info_people{
    margin: 5px 20px 5px 50px;
    padding: 10px 10px 10px 10px;
    text-align: left;
}

.people_selector{
    padding: 10px 10px 10px 10px;
}
.available_spots{
    overflow-y: scroll;
}
@media only screen and (max-width: 700px) {
  .main_component_holder{
    margin-left: -10%;
  }
}
.radio_options{
    margin: 1rem;
    padding-left: 1.5rem;
    font-size: 20px;
}
</style>