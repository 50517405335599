// renders all of the components required to
// make the form for completing the data required for the form

<template>
    <div class="main_container_form container generic_floating_container">
        <div class="">
                <div class="modal-title">
                <BookingFormHeader :amount=selected_spot.formated_price :event_name=event_selected.name
                    :people_number=booking.numberPeople :selected_date="new Date(date_selected).toDateString()" />
                </div>
                <HotelGuestCheck />
                <BookerInformationForm />

                <!-- here we iterate for the number of participants to use -->
                <!-- <div class="participat_container">
                    <h3 class="form_title">
                        Participants
                    </h3>
                    <div class="participants">
                        <div class="guest_form" v-for="guest in guests" :key='guest.position'>
                            <GuestForm :guest_info="guest" :readonly_input="true"/>
                        </div>
                    </div>

                </div> -->
                <div class="cancelation_policy">
                    <h3 class="form_title">
                        Cancel Policy
                    </h3>
                    <PolicyTerms />

                </div>
                <div class="modal-title" v-if="selected_spot.formated_price">
                <BookingFormFooter :amount=selected_spot.formated_price :event_name=event_selected.name
                    :people_number=booking.numberPeople
                    :selected_date="new Date(date_selected).toDateString()"
                    @storeBooking='storeBooking' v-if="parseFloat(selected_spot.formated_price.replace('$',''))>0.00 && !isTortugaBay" />

                <BookingFormFooter :amount=selected_spot.formated_price :event_name=event_selected.name
                    :people_number=booking.numberPeople
                    :selected_date="new Date(date_selected).toDateString()"
                    @storeBooking='completeFreeBooking' v-else />
                </div>
        </div>
        <ModalDialog form_title="Cancel Booking"
         action_btn_render_class="btn btn-danger"
         action_btn_title="Cancel Booking"
         :message="'Are you sure you want to cancel the booking for the event: '+event_selected.name+' ?'"
         action_name="cancelBooking"
         @performEmit="performEmit"/>

    </div >

</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import BookerInformationForm from '../components/BookerInformationForm.vue'
import GuestForm from '../components/GuestForm.vue'
import BookingFormHeader from '../components/BookingFormHeader.vue'
import BookingFormFooter from '../components/BookingFormFooter.vue'
import PolicyTerms from '../components/PolicyTerms.vue'
import ModalDialog from '../components/modalDialog.vue'
import useVuelidate from '@vuelidate/core'
import HotelGuestCheck from '../components/HotelGuestCheck.vue'
export default {
    setup: () => ({ v$: useVuelidate() }),
    name:"BookingForm",
    props:{

    },
    components:{
        BookerInformationForm,
        GuestForm,
        BookingFormHeader,
        BookingFormFooter,
        PolicyTerms,
        ModalDialog,
        HotelGuestCheck

    },
    computed: mapGetters(['guests','event_selected', 'booker',
                         'booking', 'selected_spot',
                         'date_selected', 'prop_token',
                         'property_fetcher', 'isTortugaBay']),
    methods:{
        ...mapActions(['cancelBooking',
                       'stageBookingBackend',
                       'freeBookingComplete']),
        performEmit(data){
            /*
                it performs the action based on existing name
                this takes an object with the name of the task
                and the item to process in case there's one.

                need to make this a promise so that it works
                asynchronously.
            */
            this.$store.dispatch('cancelBooking');
            document.getElementById('close_modal_btn').click();
            this.$toast.add({severity:'success',
                             summary:'Booking Canceled',
                             detail: "Your booking has been successfully canceled",
                                         life:7000})
            this.$router.push({name:'property_event_list',
                               prop_id:this.prop_token});

        },
        async storeBooking(){

            const full_validated = await this.$store.dispatch('runValidations',
                                                             'BookingForm');
           var send_request = false;

           if(full_validated.passed){
            //    here we validate the fields are not empty and validate the room
                if (this.property_fetcher.configuration.validate_property_guest){
                    const fields_validation = await this.$store.dispatch('runValidations',
                                                                         'HotelGuestRoom')
                    if (fields_validation.passed){
                        const final_validation = await this.$store.dispatch('validateHotelGuest');
                        if(final_validation.status){
                            send_request = true;
                        }
                        else{

                            // here we use toastr for displaying the error
                            this.$toast.add({severity:'error',
                                             summary:'Error Message',
                                             detail: final_validation.message,
                                             life:7000})
                        }
                    }
                    else{
                        // here we use toastr for displaying the error
                        this.$toast.add({severity:'error',
                                summary:'Error Message',
                                detail: fields_validation.error,
                                life:7000})

                    }

                }
                else{
                    send_request = true;
                }
                if (send_request){
                    this.$store.dispatch('stageBookingBackend').then(
                    (response)=>{
                    if(response.status){
                            this.$toast.add({severity:'success',
                                            summary:'Booking successfully created!!',
                                            detail: response.message,
                                            life:7000})

                        // here we redirect to the confirmation
                        this.$router.push({name:'confirm_booking',
                                prop_id:this.prop_token,
                                event_id:this.event_selected.productId})
                    }
                    else{
                            // here we use toastr for displaying the error
                            this.$toast.add({severity:'error',
                                            summary:'Error Message',
                                            detail: response.message,
                                            life:7000})
                        }
                    }
                    )


                }
           }
           else{
               // here we use toastr for displaying the error
                this.$toast.add({severity:'error',
                                summary:'Error Message',
                                detail: full_validated.error,
                                life:7000})

           }
           },

        async completeFreeBooking(){
             /*
            here we need to validate
            all of the fields that have been rendered in the form
            and also need ot send the request so that
            we verify if there's a holding or not with the service.

            also we redirect to the page once this is done properly.

            We need to add the validations to this form

            Need to modify this so that it works
            with the validation and everything else.
            */
           const full_validated = await this.$store.dispatch('runValidations',
                                                             'BookingForm');
           var send_request = false;

           if(full_validated.passed){
            //    here we validate the fields are not empty and validate the room
                if (this.property_fetcher.configuration.validate_property_guest){
                    const fields_validation = await this.$store.dispatch('runValidations',
                                                                         'HotelGuestRoom')
                    if (fields_validation.passed){
                        const final_validation = await this.$store.dispatch('validateHotelGuest');
                        if(final_validation.status){
                            send_request = true;
                        }
                        else{

                            // here we use toastr for displaying the error
                            this.$toast.add({severity:'error',
                                             summary:'Error Message',
                                             detail: final_validation.message,
                                             life:7000})
                        }
                    }
                    else{
                        // here we use toastr for displaying the error
                        this.$toast.add({severity:'error',
                                summary:'Error Message',
                                detail: fields_validation.error,
                                life:7000})

                    }

                }
                else{
                    send_request = true;
                }
                if (send_request){
                    this.$store.dispatch('freeBookingComplete').then(
                    (response)=>{
                    if(response.status){
                            this.$toast.add({severity:'success',
                                            summary:'Booking successfully created!!',
                                            detail: response.message,
                                            life:7000})

                        // here we redirect to the confirmation
                        this.$router.push({name:'booking_completed',
                                prop_id:this.prop_token,
                                event_id:this.event_selected.productId})
                    }
                    else{
                            // here we use toastr for displaying the error
                            this.$toast.add({severity:'error',
                                            summary:'Error Message',
                                            detail: response.message,
                                            life:7000})
                        }
                    }
                    )


                }
           }
           else{
               // here we use toastr for displaying the error
               if (this.property_fetcher.configuration.validate_property_guest){
                    const fields_validation = await this.$store.dispatch('runValidations',
                                                                         'HotelGuestRoom')
                    // if (!fields_validation.passed){
                    //     // here we use toastr for displaying the error
                    //     this.$toast.add({severity:'error',
                    //                         summary:'Error Message',
                    //                         detail: fields_validation.message,
                    //                         life:7000})
                    // }
                }
                this.$toast.add({severity:'error',
                                summary:'Error Message',
                                detail: full_validated.error,
                                life:7000})

           }
        }

    }
}
</script>

<style>
.cancelation_policy{
    text-align: left;
    padding: 5px 5px 5px 5px;

}
.participants{
    text-align: left;
	padding: 5px 5px 5px 5px;
}
.form_title{
	text-align: left;
	padding: 25px 5px -5px 11px;
    color: #8c8c8c;

}
.main_container_form{
    background-color: #f7f7f7;
    padding: 0px 0px 0px 15px;
}
.primary_col{
    max-width: 38%;
}
@media screen and (max-width:900px) {
    .primary_col{
        max-width: 100%;
    }
}

</style>
