// Basically here we will manage the number of people that can be selected

<template>
  <div class="container">
      <h4 class="title_container">
        {{title}}
      </h4>
      <div class="row">
        <div class="col-sm-3 btn_holder"> <Button title="" icon_class="cil-minus" render_class="btn btn-outline-success" @click="decreaseNumberPeople"/> </div>
         <h4 class="booking_people_number col-sm-3">
            {{booking.numberPeople}}
        </h4>  
          <div class="col-sm-3 btn_holder"> <Button title="" icon_class="cil-plus" render_class="btn btn-outline-success" @click="increaseNumberPeople"/> </div>
      </div>
  </div>
</template>

<script>
import Button from '../components/Button.vue'
import {mapGetters, mapActions} from 'vuex';
export default {
    name:'NumberPeople',
    computed: mapGetters(['booking']),
    props:{
        minPeopleCount:{
            type: Number,
            default: 1
        },
        maxPeopleCount:{
            type: Number
        },
        title:{
            type:String,
        }
    },
    methods:{
        ...mapActions['changeNumberGuests', 'changeDisplayLoader'],
        increaseNumberPeople(){
            if (this.booking.numberPeople < this.maxPeopleCount){
                this.$store.dispatch('changeNumberGuests','increase')
                // changeNumberGuests('increase');
                // this.dispatch('changeNumberGuests','increase');
                // continue with the search
            }
            else{
                this.$toast.add({severity:'error',
                                         summary:'Amount of people exceeded',
                                         detail: `the limit amount of people allowed is ${this.maxPeopleCount}`,
                                         life:7000})
            }
        },
        decreaseNumberPeople(){

            if (this.booking.numberPeople > this.minPeopleCount){
                this.$store.dispatch('changeNumberGuests','decrease');
                // continue with the search
            }
            else{
                this.$toast.add({severity:'error',
                                 summary:'Amount of people error',
                                 detail: `the minumun amount of people allowed is ${this.minPeopleCount}`,
                                         life:7000})
            }

        }
    },
    components:{
        Button
    }

}
</script>

<style scoped>
.booking_people_number {
	color: green;
	margin-left: 53px;
	margin-top: 12px;
    width:4%;
}
.btn_holder{
    margin-left: 10px;
    width:15px;
}
.title_container{
    text-align: left;
    padding-left: 18px;
}
</style>
