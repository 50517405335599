<template>
      <div class="main_container_form container generic_floating_container">
        <div class="inner_container">
                <div class="modal-title">
                <PaymentFormHeader :amount=selected_spot.formated_price :event_name=event_selected.name
                    :people_number=booking.numberPeople :selected_date="new Date(date_selected).toDateString()" />
                </div>

                <!-- here we iterate for the number of participants to use -->
                <div class="participat_container">
                    <PaymentFormBody />
                </div>
               
                <div class="modal-title">
                <PaymentFormFooter @payBooking='completePayment' />
                </div>
        </div>
        <ModalDialog form_title="Cancel Booking" 
         action_btn_render_class="btn btn-danger"
         action_btn_title="Cancel Booking"
         :message="'Are you sure you want to cancel the booking for the event: '+event_selected.name+' ?'"
         action_name="cancelBooking"
         @performEmit="performEmit"/>

    </div >
</template>

<script>
import PaymentFormHeader from '../components/PaymentFormHeader.vue';
import PaymentFormFooter from '../components/PaymentFormFooter.vue'
import {mapActions, mapGetters} from 'vuex';
import ModalDialog from '../components/modalDialog.vue'
import PaymentFormBody from '../components/PaymentFormBody.vue'


export default {
    name:'PaymentForm',
    components:{
        PaymentFormHeader,
        PaymentFormFooter,
        ModalDialog,
        PaymentFormBody
    },
    computed:{
        ...mapGetters(['guests','event_selected', 'booker',
                         'booking', 'selected_spot',
                         'date_selected', 'prop_token']),
    },
    methods:{
        ...mapActions(['cancelBooking',
                       'changeDisplayMainHeader',
                       'completeBookingPayment',
                       'runValidations']),
        performEmit(data){
            /*
                it performs the action based on existing name
                this takes an object with the name of the task
                and the item to process in case there's one.

                need to make this a promise so that it works
                asynchronously.
            */
            this.$store.dispatch('cancelBooking');
            document.getElementById('close_modal_btn').click();
            this.$toast.add({severity:'success',
                             summary:'Booking cancelled',
                             detail: `your booking has been successfully cancelled`,
                                         life:7000})
            this.$router.push({name:'property_event_list',
                               prop_id:this.prop_token});
        },
        async completePayment(){
            const full_validated = await this.$store.dispatch('runValidations',
                                                              'PaymentForm');
            if(full_validated.passed)
            {
                this.$store.dispatch('completeBookingPayment').then((response)=>{
                    if (response.status){
                        this.$toast.add({severity:'success',
                                        summary:'Payment completed',
                                        detail: response.message,
                                            life:7000})

                        this.$store.dispatch('holdTimerConfig', false).then(() => {
                            this.$router.push({
                            name:'booking_detail',
                            prop_id: this.prop_token
                            })   
                        });
                    }
                    else{
                        this.$toast.add({severity:'error',
                                        summary:'Payment error',
                                        detail:response.message,
                                            life:7000})
                    }
                }).catch((response)=>{
                this.$toast.add({severity:'error',
                                     summary:'Payment error',
                                     detail: response.response.data.error,
                                         life:7000})
                })
    
            }
            else{
                // here we use toastr for displaying the error   
                this.$toast.add({severity:'error',
                                summary:'Error Message',
                                detail: full_validated.error,
                                life:7000})
            }
    },
    created(){
        this.$store.dispatch('changeDisplayMainHeader', true)
    }
  }

}
</script>

<style scoped>
.cancelation_policy{
    text-align: left;
    padding: 5px 5px 5px 5px;

}
.participants{
    text-align: left;
	padding: 5px 5px 5px 5px;
}
.form_title{
	text-align: left;
	padding: 25px 5px -5px 11px;
    color: #8c8c8c;

}
.main_container_form{
    background-color: #f7f7f7;
    padding: 0px 10px 0px 15px;
    /* max-width: 50%; */
}
.primary_col{
    max-width: 38%;
}
@media screen and (max-width:900px) {
    .primary_col{
        max-width: 100%;
    }
    .main_container_form{
        max-width: 90%;

    }
}
@media only screen and (min-width: 900px) {
  /* .main_container_form{
        max-width: 70%;

    } */
}
</style>