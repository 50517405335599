// this component holds the main  booker
<template>
  <div class="user_container">
      <h3 class="form_title" v-if="!detailMode">
          Please complete your information
      </h3>
      <div class="form_container">
          <div class="row">
            <div class="col-md-6 primary_col">
                <Input input_type="text" label_title="Name"
                 v-model="firstName"
                 :disable_input="detailMode"
                 :validation="ruleset_validation.BookingForm.booking_creator.first_name"
                 html_unique="booker_first_name"
                 :required_f='false'
                 disabled
                 />
                <Input input_type="text" label_title="Lastname" v-model="lastName"
                :disable_input="detailMode"
                 :validation="ruleset_validation.BookingForm.booking_creator.last_name"
                 html_unique="booker_last_name"
                 :required_f='false'
                 />
                <LanguageSelect v-if="!detailMode" label_title="Please select your preferred language"/>
            </div>

            <div class="col-md-6">
                 <Input input_type="email" label_title="Email" v-model="email"
                 :disable_input="detailMode"
                 :validation="ruleset_validation.BookingForm.booking_creator.email"
                 html_unique="booker_email"
                 :required_f='false' />

                  <Input input_type="tel" label_title="Phone Number"
                  v-model="phoneNumber"
                  :disable_input="detailMode"
                  :validation="ruleset_validation.BookingForm.booking_creator.phone_number"
                  html_unique="booker_phone_number"
                  :required_f='false'
                  masked=""
                  />

            </div>
        </div>

      </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Input from './Input.vue'
import { required, email, minLength } from '@vuelidate/validators'
import LanguageSelect from './LanguageSelectComponent.vue'
// import useVuelidate from '@vuelidate/core'

export default {
    name: "BookerInformationForm",
    props:{
        detailMode: {
            type: Boolean,
            default: false,
        },
    },
    validators(){
        return{
            firstName:{required},
            lastName:{required},
            email: {required, email},
            phoneNumber:{required,
                         min: minLength(11)},
            birthDate:{required}
        }
    },
    computed:{
        ...mapGetters(['booker','ruleset_validation', 'isCollaborator', 'collaborator']),

        firstName:{
            get(){
                return this.booker.first_name;
            },
            set(value){
                var sender = {key:'first_name',
                              val: value}
                              
                this.$store.dispatch('createFakeBookingGuest', value)
                this.$store.dispatch('fillBookerInfo', sender);

            }

        },
        lastName:{
            get(){
                return this.booker.last_name;
            },
            set(value){
                var sender = {key:'last_name',
                              val: value}

                this.$store.dispatch('fillBookerInfo', sender);
            }

        },
        email:{
            get(){
                return this.booker.email;
            },
            set(value){
                var sender = {key:'email',
                              val: value}
                this.$store.dispatch('fillBookerInfo', sender);
            }

        },
        phoneNumber:{
            get(){
                return this.booker.phone_number;
            },
            set(value){
                var sender = {key:'phone_number',
                              val: value}
                this.$store.dispatch('fillBookerInfo', sender);
            }

        },
        birthDate:{
            get(){
                return this.booker.birthdate;
            },
            set(value){
                var sender = {key:'birthdate',
                              val: value}
                this.$store.dispatch('fillBookerInfo', sender);
            }

        },
    },
    methods:{
        ...mapActions(['fillBookerInfo', 'createFakeBookingGuest']),
    },
    components:{
        Input,
        LanguageSelect
    },
    mounted() {
        if(this.isCollaborator){
            this.firstName = this.collaborator.name;
            this.email = this.collaborator.email;
            this.booker.username = this.collaborator.email;
        }
    },
}
</script>

<style scoped>

</style>
