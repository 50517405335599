<template>
  <div class="guest_info_container flex">
    <div class="row">
      <div class="col-md-6 field_forms">
      <Input input_type="string" label_title="Last 4 digits for the credit card used in the booking payment"
              v-model="CardNumber"
              :validation="ruleset_validation.cancelBooking.last_4_digit_card"
              html_unique="cancel_form_card_number"
              :required_f='false'
              />
      <Input input_type="text" label_title="Booking approval id"
        v-model="ConfirmationNumber"
        :validation="ruleset_validation.cancelBooking.confirmation_payment_code"
        html_unique="confirmation_number_cancel"
        :required_f='false'
        />
      </div>

    </div>
      <div class="footer_container_actions">
        <div class="row buttons_container">
            <div class="col-md-6">
                <GenericBtn title="Cancel"
                  render_class="btn btn-outline-danger"
                  @click="cancelRefundProcess"/>

                <GenericBtn title="Cancel Booking"
                render_class="btn btn-danger"
                data-bs-toggle='modal' data-bs-target='#modal_container' v-if="can_send_refund"/>
                <GenericBtn title="Cancel Booking"
                render_class="btn btn-danger" data-bs-toggle='modal'
                data-bs-target='#modal_container' disabled v-else/>
            </div>
      </div>
  </div>
    <!-- <ModalDialog form_title="Cancel Booking"
      action_btn_render_class="btn btn-danger"
      modal_id='cancel_process_modal'
      action_btn_title="Cancel"
      :message="'Are you sure you want to cancel your refund process ?'"
      action_name="cancelBooking"
      @performEmit="performEmit"/> -->
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Input from './Input.vue'
import GenericBtn from './Button.vue'
import ModalDialog from './modalDialog.vue'
export default {
  name:'CancelBookingForm',
  components: {
    Input,
    GenericBtn,
    ModalDialog
    },
    computed: {
    ...mapGetters(['booking_cancel', 'ruleset_validation', 'can_send_refund', 'redirectUrl']),
    CardNumber:{
      get(){
        return this.booking_cancel.last_4_digit_card
      },
      set(value){
          var dict_data = {key:'last_4_digit_card',
                              val: value,
                              guest: this.guest_info}
          this.$store.dispatch('fillRefundBookingForm', dict_data)
      }
    },
     ConfirmationNumber:{
      get(){
        return this.booking_cancel.confirmation_payment_code
      },
      set(value){
          var dict_data = {key:'confirmation_payment_code',
                              val: value
                              }
          this.$store.dispatch('fillRefundBookingForm', dict_data)
      }
    }
     },
  methods: {
    ...mapActions(['fillRefundBookingForm', 'finishRefundProcess']),
    performEmit(data){
      // performs the event setup.
      console.log('hereee')
    },
    cancelRefundProcess(){
      // cancels the refund process
      let payload = {redirect_url: this.redirectUrl}
      this.$store.dispatch('finishRefundProcess', payload);
    }
  },
}
</script>

<style scoped>
.buttons_container{
  margin-left: -20%;
}
.field_forms{
  width: 40%;
}
@media screen and (max-width:900px) {
  .field_forms{
    width: 100%;
  }

}
</style>
