// this is just the header based on the existing data

<template>
<div class="title_container">
    <h2 class="form_title">
        <b>
            Your payment Includes
        </b>
    </h2>
    <div class="row">
        <div class="col-md-9">
            <h3 class="event_name_title">
                {{event_name}}
            </h3>
        </div>
        <div class="col-md-3 total_liner">
            <h5>Total Amount</h5>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
        <h5> {{selected_date}}</h5> 
        </div>
        <div class="col-md-4">
            <h5> <b>{{people_number}} Pax  USD{{ amount }} </b></h5>
        </div>
        <div class="col-md-4 total_liner">
            <h5> <b>USD{{ amount }} </b></h5>
        </div>
    </div>    
  <hr>
</div>
</template>

<script>
export default {
    name: "BookingFormHeader",
    props:{
        event_name:{
            type: String
        },
        selected_date:{
            type: String
        },
        people_number:{
            type: Number
        },
        amount:{
            type: String
        }
    }

}
</script>

<style scoped>
.title_container{
    text-align: left;
    padding: 10px 5px 5px 10px;
    max-width: 52%;
}
.event_name_title{
    color:green;
}
.total_liner{
    text-align: right;
}
/* .title_container{
    max-width: 100%;
} */
@media screen and (max-width:900px) {
    .title_container{
    max-width: 100%;
}
}
</style>