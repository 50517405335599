/*
this is going to be the container to gather the information in order to make sure
the selection of the booking is right.

this contains: 
- side booking container 
- Event detail 
- Button
*/

<template>
  <div class="container fluid main_container generic_floating_container">
      <div class="row">
          <div class="col-md-8 detail_container">
              <!-- detail container -->
              <EventDetail :selectedEvent="event_selected"/>
              <Button @click="goBack" title='Cancel booking' render_class="btn btn-outline-danger cancel_btn" />
          </div>
          <div class="col-md-4 side_container">
              <!-- side container -->
              <SideBookingBar :selected_event="event_selected"/>
          </div>
      </div>
  </div>
</template>

<script>
import SideBookingBar from '../components/SideBooking.vue';
import EventDetail from '../components/EventDetail.vue';
import {mapGetters, mapActions} from 'vuex';
import Button from '../components/Button.vue'

export default {
  name: 'EventBooking',
  components: {
    SideBookingBar,
    EventDetail,
    Button
  },
  computed:mapGetters(['event_selected', 'booking', 'isCollaborator']),
  methods:{
       ...mapActions(['loadSelectedEventStart',
                      'resetBookingInfo',
                      'changeDisplayMainHeader',
                      'getEventConfiguration',
                      ]),
       goBack(){
          var property_id = this.$route.params.prop_id;
          this.$store.dispatch('resetBookingInfo');
          this.$toast.add({severity:'success',
                           summary:'Booking cancelled',
                           detail: `your booking has been successfully cancelled`,
                                         life:7000})
          this.$router.push({name:'property_event_list', params:{prop_id:property_id}})
       },
       onGetEventConfiguration() {
        this.$store.dispatch('getEventConfiguration');
       },

  },
  async created(){
    this.$store.dispatch('changeDisplayMainHeader', true)
    var selected = this.$route.params.event_id;
    var prop_id = this.$route.params.prop_id;
    this.$store.dispatch('loadSelectedEventStart',[prop_id, selected]);
  },
  mounted() {
    if (this.isCollaborator) {
      this.onGetEventConfiguration();
    }
  },
}
</script>

<style scoped>
.main_container{
    background-color: #f7f7f7;
    margin-bottom:5%;
}
.detail_container{
	flex: 0 0 auto;
	/* width: 70%; */
	max-height: 60%;
    padding: 10px 10px 10px 10px;
}
.side_container{
/* max-width:29%; */
}
.cancel_btn{
    margin-right:83%;
}

</style>