import axios from 'axios'
const api = ()=>{return {api_url: process.env.VUE_APP_BACKEND_API_URL}}
const api_url = api().api_url
const urls = {
    gather_events: `${api_url}/api/gather_events`,
    search_month_availability: `${api_url}/api/search_month_availability/`,
    search_availability: `${api_url}/api/search_availability/`,
    create_booking_hold: `${api_url}/api/booking_holds/`,
    stage_booking: `${api_url}/api/stage_booking/`,
    complete_free_booking: `${api_url}/api/complete_free_booking/`,
    complete_paid_booking: `${api_url}/api/complete_booking/`,
    search_hotel_guest: `${api_url}/api/search_guest/`,
    preferred_language: `${api_url}/api/supported-languages/`,
    cancel_booking: `${api_url}/api/cancel-booking/`,
    update_booking: `${api_url}/api/property_handle/booking/`,
    renew_booking_hold: `${api_url}/api/booking_holds/renew_hold/`,
    get_booking_hold: `${api_url}/api/booking_holds`,
    delete_booking_hold: `${api_url}/api/booking_holds/`,
    validate_request_access_hash: `${api_url}/api/collaborator/generate_access_request/`,
    get_collaborator_event_configuration: `${api_url}/api/collaborator_event_configuration`,
    request_otp_code: `${api_url}/api/tortuga_bay/send_otp`,
    validate_otp_code: `${api_url}/api/tortuga_bay/validate_otp/`,
}
const getBookingCreator = () =>{
  return {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    username: '',
    preferred_language: 'en_US'
  }
}

const getCancelBookingForm = () =>{
  return {
    "last_4_digit_card": '',
    "confirmation_payment_code": "",
  }
}

const getSuccessMessageRefund = () =>{
  return {
    booking_number: '',
    cancelation_time: '',
    verification_code: '',
    email: ''
  }
}

const getValidationSet = () =>{
  return {
    BookingForm:{
        validated: false,
        booking_creator: {
            first_name: {
                deno:'B',
                field: 'first_name',
                parent:'BookingForm',
                validated: false,
                showErrors: false,
                validators: [{
                    error: 'this value is required',
                    validator: 'required',
                    order: 0
                }]
            },
            last_name: {
                deno:'B',
                field: 'last_name',
                parent:'BookingForm',
                validated: false,
                showErrors: false,
                validators: [{
                    error: 'this value is required',
                    validator: 'required',
                    order: 0
                }]
            },
          //   preferred_language: {
          //     deno:'B',
          //     field: 'preferred_language',
          //     parent:'BookingForm',
          //     validated: false,
          //     showErrors: false,
          //     validators: [{
          //         error: 'this value is required',
          //         validator: 'required',
          //         order: 0
          //     }]
          // },
            email: {
                deno:'B',
                field: 'email',
                parent:'BookingForm',
                validated: false,
                showErrors: false,
                validators: [{
                    error: 'this value is required',
                    validator: 'required',
                    order: 0
                },
                {
                    error: 'this value must be an email',
                    validator: 'email',
                    order: 1

                },]
            },
            phone_number: {
                deno:'B',
                field: 'phone_number',
                parent:'BookingForm',
                validated: false,
                showErrors: false,
                validators: [
                    {
                    error: 'this value is required',
                    validator: 'required',
                    order: 0

                },
                {
                    error: 'please enter a valid phone number',
                    validator: 'phoneNumber',
                    limit: 13,
                    order: 1

                }
            ]
            },
        },
        guests: []
    },
    PaymentForm:{
        validated: false,
        name: {
            field: 'name',
            parent:'PaymentForm',
            validated: false,
            showErrors: false,
            validators: [{
                error: 'this value is required',
                validator: 'required',
                order: 0
            }]
        },
        number: {
            field: 'number',
            parent:'PaymentForm',
            validated: false,
            showErrors: false,
            validators: [{
                error: 'this value is required',
                validator: 'required',
                order: 0
            },
            {
                error: 'this value must be of 16 digits',
                validator: 'equal',
                limit: 16,
                order: 1

            },
            // {
            //   error: 'this value must be of at least 16 digits',
            //   validator: 'lessThan',
            //   limit: 17,
            //   order: 1

            // },
            // {
            //   error: '',
            //   validator: 'greaterThan',
            //   limit: 14,
            //   order: 1

            // }
          ]
        },
        expiration: {
            field: 'expiration',
            parent:'PaymentForm',
            validated: false,
            showErrors: false,
            validators: [{
                error: 'this value is required',
                validator: 'required',
                order: 0
                },
                {
                    error: 'this value must be of 4 digits',
                    validator: 'equal',
                    limit: 4,
                    order: 1
                },
                {
                    error: 'this value must be in the MM/YY format',
                    validator: 'expiration_card',
                    order: 2
                }
            ]
        },
        cvc: {
            field: 'cvc',
            parent:'PaymentForm',
            validated: false,
            showErrors: false,
            validators: [{
                error: 'this value is required',
                validator: 'required',
                order: 0
            },
            {
                error: 'this value must be of 3 digits',
                validator: 'equal',
                limit: 3,
                order: 1
            }]
        },
    },
    HotelGuestRoom: {
        validated: false,
        last_name: {
            field: 'last_name',
            parent:'HotelGuestRoom',
            validated: false,
            showErrors: false,
            validators: [{
                error: 'this value is required',
                validator: 'required',
                order: 0
            }]
        },
        room_number: {
            field: 'room_number',
            parent:'HotelGuestRoom',
            validated: false,
            showErrors: false,
            validators: [{
                error: 'this value is required',
                validator: 'required',
                order: 0
            }]
        },

    },
    cancelBooking: {
      validated: false,
      last_4_digit_card: {
          field: 'last_4_digit_card',
          parent:'cancelBooking',
          validated: false,
          showErrors: false,
          validators: [{
              error: 'this value is required',
              validator: 'required',
              order: 0
          },
          {
            error:' you should only enter the last 4 digits of the used card.',
            validator:'equal',
            limit: 4,
            order: 1
          },
          {
            error:' you should only enter the digits here',
            validator:'number',
            limit: 4,
            order: 2
          },
        ]
      },
      confirmation_payment_code: {
          field: 'confirmation_payment_code',
          parent:'cancelBooking',
          validated: false,
          showErrors: false,
          validators: [{
              error: 'this value is required',
              validator: 'required',
              order: 0
          }]
      },

    }

  }
}
const getDefaultState = () => {
    return {
        startHoldTimer: false,
        property: {},
        property_loaded: false,
        property_token: "",
        sel_language: '',
        finished: false,
        event_list: [],
        selected_event: {},
        languages_available: [],
        show_loader: false,
        selected_date: "",
        redirect_url: "",
        booking_information:{
            numberPeople: 1,
            category:'Cadults',
            start_time: "this",
            end_time: "this",
            price: ""
        },
        day_availability_spots: [],
        available_spots_calendar: [],
        booking_creator: getBookingCreator(),
        guest_list: [],
        formated_calendar_dates: [],
        indexable_available_dates: {},
        booking_hold: {},
        card: {
            number: "",
            cvc: "",
            name: "",
            expiration: "",
        },
        selected_availability :{},
        accepted_terms: false,
        myself_included: true,
        backed_booking: {},
        validation_ruleset: getValidationSet(),
        payment_confirmation: {},
        hotel_guest:{
            room_number:"",
            last_name:"",
            request_type: {},
            isTortugaBay: false,
        },
      cancel_booking: getCancelBookingForm(),
      cancellation_error_message: '',
      cancellation_error_code: '',
      show_errors_cancellation: false,
      send_refund: false,
      sucessRefund: getSuccessMessageRefund(),
      collaborator: {},
      isCollaborator: false,
      event_configuration: {},
      collaborator_included: true,
      collaborator_used_slots: 0,
      isTortugaBay: false,
    }
}

const state = getDefaultState();

const getters = {
    property_fetcher: (state) => state.property,
    available_languages: (state) => state.languages_available,
    prop_loaded: (state) => state.property_loaded,
    prop_token: (state) => state.property_token,
    booking: (state) => state.booking_information,
    event_selected: (state) => state.selected_event,
    events: (state) => state.event_list,
    finished_loading: (state)=>state.finished,
    card_getter: (state)=> state.card,
    date_selected: (state) => state.selected_date,
    available_calendar_spots: (state) => state.available_spots_calendar,
    calendar_ready_dates: (state) => state.formated_calendar_dates,
    availability_list: (state) => state.day_availability_spots,
    selected_spot: (state) => state.selected_availability,
    index_available: (state) => state.indexable_available_dates,
    booking_on_hold: (state) => state.booking_hold,
    guests: (state) => state.guest_list,
    booker: (state) => state.booking_creator,
    terms_accepted: (state) => state.accepted_terms,
    staged_booking: (state) => state.backed_booking,
    ruleset_validation: (state) => state.validation_ruleset,
    loading: (state) => state.show_loader,
    booking_confirmation: (state) => state.payment_confirmation,
    guest_hotel: (state) => state.hotel_guest,
    language_preferred: (state) => state.sel_language,
    booking_cancel: (state) => state.cancel_booking,
    response_error_message: (state) => state.cancellation_error_message,
    response_error_code: (state) => state.cancellation_error_code,
    show_cancellation_errors: (state) => state.show_errors_cancellation,
    can_send_refund: (state) => state.send_refund,
    refund_success: (state) => state.sucessRefund,
    redirectUrl: (state) => state.redirect_url,
    holdTimer: (state) => state.startHoldTimer,
    collaborator: (state) => state.collaborator,
    isCollaborator: (state) => state.isCollaborator,
    event_configuration: (state) => state.event_configuration,
    collaborator_included: (state) => state.collaborator_included,
    isTortugaBay: (state) => state.isTortugaBay,

};
const actions = {
    async verifyAccessRequestHash({state}, value){
        this.dispatch('changeDisplayLoader', true);
        let header = {'Authorization': 'Token '+value.property_token,
            'Content-Type':'application/json',
        };
        await axios.get(`${urls.validate_request_access_hash}${value.employee_code}/${value.hash}`,{headers:header}).then((response) => {
            state.collaborator = response.data;
            state.booking_creator.first_name = response.data.name;
            state.booking_creator.email = response.data.email;
            state.isCollaborator = true;
            console.log(state.booking_creator);

            this.dispatch('changeDisplayLoader', false);
            return {status:true}
        }).catch((error) => {
             this.dispatch('changeDisplayLoader', false);
             console.log("Error");
             window.location.replace(process.env.VUE_APP_GH_DIGITAL_URL);
             return {status:false, error: error.message}
        });
    },
    clearCollaboratorData({state}){
        state.isCollaborator = false;
        state.collaborator = {};
    },
    async sendOTPValidation({state}, collaboratorCode){

        var header = {'Authorization': 'Token '+ state.property_token,
            'Content-Type':'application/json',
            }

        return await axios.get(`${urls.request_otp_code}/collaborator/${collaboratorCode}/`, {headers:header}).catch((error) => {
            return error.response;
        });
    },
    async validateOTP({state},collaboratorData){
        var header = {'Authorization': 'Token '+ state.property_token,
            'Content-Type':'application/json',
            }

        return await axios.post(`${urls.validate_otp_code}`, collaboratorData, {headers:header}).catch((error) => {
            return error.response;
        });
    },
    async getEventConfiguration({ state }){
        this.dispatch('changeDisplayLoader', true);
        var header = {'Authorization': 'Token '+ state.property_token,
                      'Content-Type':'application/json',
                      }
        await axios.get(`${urls.get_collaborator_event_configuration}/collaborator/${state.collaborator.employee_code}/event/${state.selected_event.productId}`, {headers:header})
        .then((response) => {
            state.event_configuration = response.data.result;
        }).catch((error) => {
            this.dispatch('changeDisplayLoader', false);
            return {status:false, error: error.message}
        });
    },
    async changeDisplayMainHeader({commit},value){
        commit('changeDisplayMainHeader', value);
    },
    async changeDisplayLoader({commit},value){
        commit('changeDisplayingLoader', value);
    },
    async resetStateToDefault({commit}){
        /*
            resets the state as if it was just
            created. this is used when it was already
            authenticated but provides a different token
            for security measures.
        */
        commit('resetState');
    },
    async selectDesiredLanguage({commit}, data){
      commit('setBookerLanguage', data);
    },
    async getPreferredLan({commit, state}, property_id){
      /*
        basically makes sure you have
        a list of languages to choose
        in order to determine what's your
        preferred language.
      */
      var header = {'Authorization': 'Token '+property_id,
                  'Content-Type':'application/json',
                  }
      const response = await axios.get(`${urls.preferred_language}`,
                                 {headers: header,
                                 mode:'cors'})
      var data = response.data.response.data;
      commit('loadCustomerLanguages', data);
      this.dispatch('selectDesiredLanguage', data[0]);
      // console.log(data[0], state.sel_language)


    },
    async getEventList({commit}, property_id){
        // we connect this with the backend
        // and return the rest them
      commit('changeDisplayingLoader', true);
      this.dispatch('getPreferredLan',property_id);
      var header = {'Authorization': 'Token '+property_id,
                    'Content-Type':'application/json',
                    }
      var cleanName = (event)=>{event.name=(event.name.includes('['))? event.name.substring(0, event.name.indexOf('[')): event.name}
      const response = await axios.get(`${urls.gather_events}/${property_id}/`,
                                   {headers: header,
                                   mode:'cors'})
      var data = response.data;
      data.property_token = property_id;
      data.available_events.data.forEach(cleanName);
      commit('loadInitialList', data);
      commit('changeDisplayingLoader', false);

    },
    // async searchEventDetails({commit}, property_id){
    //     // we connect this with the backend
    //     // and return the rest them
    //     var header = {'Authorization': `Token ${this.$route.params.prop_id}`,
    //                 'Content-Type':'application/json'
    //                 }
    //     this.dispatch('changeDisplayLoader', true);
    //     const response = await fetch((`${api_url}/api/gather_events/${property_id}/`,
    //                                 {headers: header,
    //                                 mode:'cors'}))
    //     const response = await axios.get()
    //     const response_data = await response.json()
    //     this.dispatch('changeDisplayLoader', false);
    //     return response_data
    //     },
    async selectEvent({commit}, event){
        this.dispatch('changeDisplayLoader', true);
        commit('loadSelectedEvent', event);
        this.dispatch('changeDisplayLoader', false);

    },

    async loadSelectedEventStart({commit, state}, list_values){
        let property_id = list_values[0]
        let event_id = list_values[1]
    //   await this.dispatch('getEventList', property_id);
      this.dispatch('changeDisplayLoader', true);
      await this.dispatch('loadEventDirect', event_id)
      this.dispatch('changeDisplayLoader', false);

    },
    async resetBookingInfo({commit, state}){
        /*
            resets the values related to the
            given booking details.
        */
        commit('resetBookingDefault');
    },
    async loadEventDirect({commit, state}, event_id ){
       let event = null;
       for (let item of state.event_list){
           if (item.productId == event_id){
               event = item;
               break;
           }
       }
       commit('loadSelectedEvent', event);
    },
    async changeNumberGuests({commit}, operation){
        /*
            changes the number of
            people to be added in the request.
            this is after the validation to be
            run.
            the only two types of operations
            accepted are : increase and decrease
        */
       if (operation == 'increase'){
            commit('increaseAmountGuests');
       }
       else{
        commit('decreaseAmountGuests');
       }
       this.dispatch('searchAvailiability')
    },
    async SearchEventMonthAvailability({commit, state}, month_year){
        /*
        gets the initial days to paint in the
        initial available dates in the given
        object.
        */
       var property_id  = state.property_token
       let header = {'Authorization': 'Token '+property_id,
       'Content-Type':'application/json',
       }
       let request_body = {
            month: month_year.month,
            year: month_year.year,
            property_token: state.property_token,
            event_number: state.selected_event.productId,
        }
        // console.log(request_body)
        let response = await axios.post(`${urls.search_month_availability}`,
                                        request_body,{headers:header})
        if(response.status != 200){
            const res = response.data
            // console.log(res)

        }
        else{
            let data = response.data
            // console.log(data)
            commit('loadAvailableDates', data);
            this.dispatch('resetDate', month_year);
        }

    },

    async searchAvailiability({commit, state}){
        /*
        creates a post request to get
        the availability and updates the
        day availability list
        */
       var property_id  = state.property_token
       this.dispatch('changeDisplayLoader', true);
       if (state.selected_date)
        {
            let post_search_params = {
                start_time: state.booking_information.start_time,
                end_time: state.booking_information.end_time,
                people_number: state.booking_information.numberPeople,
                people_category: state.booking_information.category,
                property_token: state.property_token,
                event_number: state.selected_event.productId,
            }
            // console.log(post_search_params)
            let header = {'Authorization': 'Token '+property_id,
                          'Content-Type':'application/json',
                          }
            const response = await axios.post(`${urls.search_availability}`,
                                              post_search_params,
                                              {headers:header})
            if(response.status != 200){
                const res = response.data
                // console.log(res)

            }
            else{
                let data = response.data
                commit('loadAvailableSpots', data);
            }
        }
        else{
            this.dispatch('changeDisplayLoader', false);
            alert('In order to search, you need to select a date');
        }
        this.dispatch('changeDisplayLoader', false);

    },


    async selectDate({commit,state}, date_to_place){
        /*
            selects the date and triggers the
            search for the events available
            on the given date.
        */
        commit('setDate', date_to_place);
        this.dispatch('searchAvailiability')

    },
    async resetDate({commit, state}, month_year){
        commit('resetSelectedDate', month_year);
        this.dispatch('selectDate', state.selected_date)
    },
    async createBookingHold({commit, state}, spot){
        /*
            create a hold for the
            booking selected and
            moves for the next page.
        */
        this.dispatch('changeDisplayLoader', true);
        let property_id = state.property_token;
        let header = {'Authorization': 'Token '+property_id,
            'Content-Type':'application/json',
            };
        let request_body = {
        "property_token": property_id,
        "event_tagged_id": spot.eventId,
        "category": state.booking_information.category,
        "event_id": state.selected_event.productId,
        "number_people": state.booking_information.numberPeople
       };
       state.booking_hold_request = request_body;
       const response = await axios.post(`${urls.create_booking_hold}`,
                                         request_body,{headers:header});
       let response_data = response.data;
       if (response_data.status){
            commit('setBookingHold', {hold: response_data.response,
                                      spot: spot});
            this.dispatch('changeDisplayLoader', false);
            return {status:true}
       }
       else{
            this.dispatch('changeDisplayLoader', false);
            return {status:false, error: response_data.response}
       }
    },
    async renewBookingHold({commit, state}){
        this.dispatch('changeDisplayLoader', true);
        let property_id = state.property_token;
        let header = {'Authorization': 'Token '+property_id,
            'Content-Type':'application/json',
        };

        const response = await axios.post(`${urls.renew_booking_hold}?id=${state.booking_hold.id}`,
        state.booking_hold_request,{headers:header});
        let response_data = response.data;
        if (response_data.status){
            state.booking_hold = response_data.response;
             this.dispatch('changeDisplayLoader', false);
             return {status:true}
        }
        else{
             this.dispatch('changeDisplayLoader', false);
             return {status:false, error: response_data.response}
        }

    },
    async removeBookingHold({commit, state}){
        this.dispatch('changeDisplayLoader', true);
        let header = { 'Authorization': 'Token '+state.property_token,  'Content-Type':'application/json',}

        const response = await axios.delete(`${urls.delete_booking_hold}?id=${state.booking_hold.id}`, {headers: header});
        if(response.data.status){
            this.dispatch('changeDisplayLoader', false);
        } else {
            this.dispatch('changeDisplayLoader', false);
            return { status: false, error: response.data.response}
        }
        
    },
    async updateBooking({commit, state}){
        this.dispatch('changeDisplayLoader', true);
        let property_id = state.property_token;
        let header = {'Authorization': 'Token '+property_id,
            'Content-Type':'application/json',
        };
        
            state.guest_list.map((option, index) => {
                if(option.is_main){
                    option.first_name = state.booking_creator.first_name;
                    option.last_name = state.booking_creator.last_name;
                }
            });
        
           delete state.payment_confirmation.booking.main_guest
           delete state.payment_confirmation.booking.additional_guests

        state.payment_confirmation.booking.guests = state.guest_list;
        
        state.payment_confirmation.booking.isCollaborator = state.isCollaborator;

        const response = await axios.put(`${urls.update_booking}${state.payment_confirmation.booking.uuid}/`,
            state.payment_confirmation.booking,{headers:header});
            if (response.status){
                // commit('setBookingHold', {hold: response_data.response,
                //                           spot: spot});
                state.payment_confirmation = response.data;
                this.dispatch('changeDisplayLoader', false);
                return {status:true}
           }
           else{
                this.dispatch('changeDisplayLoader', false);
                return {status:false, error: response_data.response}
           }
    },
    async goAccepTerms({commit}, value){
        // just changes the accepted terms for the
        //given form
        commit('acceptTerms', value);
    },
    async updateCollaboratorParticipation({ state }, value){
        // just changes the accepted terms for the
        //given form
        state.collaborator_included = value;
    },
    async updateIsMain({commit}, value){
        /*
            updates the task if
            the user is the main based on
            the values provided.

            the value provided is a
        */
       commit('changeIsMain', value);
    },
    async cancelBooking({commit, state}){
        /*
        cancels the booking on memory
        and cleans everything
        */
       this.dispatch('changeDisplayLoader', true);
       let url_name=`${urls.create_booking_hold}`
       let request_data = {'holding_id': state.booking_hold.id}
       var property_id  = state.property_token
       let header = {'Authorization': 'Token '+property_id,
                     'Content-Type':'application/json',
                     }
        const response = await axios.request({
            url:url_name,
            method:'DELETE',
            headers: header,
            data: request_data
        })

        if (response.status == 200){
            // console.log('here finished')
            this.dispatch('resetBookingInfo');
            this.dispatch('changeDisplayLoader', false);

        }
        else{
            this.dispatch('changeDisplayLoader', false);
            alert('deleting the holding failed...');
        }

    },
    async stageBookingBackend({commit, state}){
        /*
            stages the booking for the user
            and moves the user to the payment page.

            this is a promise so based on the result,
            we will redirect. which means we need to
            return the promise.
        */
        this.dispatch('changeDisplayLoader', true);
        var property_id = state.property_token
        let header = {'Authorization': 'Token '+property_id,
                      'Content-Type': 'application/json',
                      };
        let url = `${urls.stage_booking}`

        let requestData = {
            "property_token": state.property_token,
            "name": state.selected_event.name,
            "event_id": state.selected_event.productId,
            "event_tagged_id": state.selected_availability.eventId,
            "holding_id": state.booking_hold.id,
            "number_people": state.booking_information.numberPeople,
            "category": state.booking_information.category,
            "scheduled_for": state.selected_availability.startTime,
            "total_payment": state.selected_availability.price.amount,
            "total_taxes":state.booking_hold.price.totalTaxes.amount,
            "currency":state.booking_hold.totalPayable.currency,
            "booker": state.booking_creator,
            "guests": state.guest_list,
            'selected_language': state.booking_creator.preferred_language
        }
        // console.log(requestData)
        // here we call validations to ensure this is valid to send.
        try{
            const response = await axios.post(url,requestData,{
                headers:header
            })
            const data = response.data
            if (data.status){
                // here we perform any type of action required.
                commit('stageBackedBooking', data)
                this.dispatch('changeDisplayLoader', false);
                return {status: true,
                        message: data.message}
            }
            else{
                this.dispatch('changeDisplayLoader', false);
                return {status: false,
                        message: data.error}
            }
        }
        catch(Error){
            this.dispatch('changeDisplayLoader', false);
            // console.log(Error)
            return {status: false,
                    message: Error.response.status === 400 ? Error.response.data.error : Error}
        }
    },
    async freeBookingComplete({commit, state}){
        /*
            stages the booking for the user
            and moves the user to the payment page.

            this is a promise so based on the result,
            we will redirect. which means we need to
            return the promise.
        */
            this.dispatch('changeDisplayLoader', true);
            try{
                var property_id = state.property_token
            let header = {'Authorization': 'Token '+property_id,
                          'Content-Type': 'application/json',
                          };
            let url = `${urls.complete_free_booking}`
            let requestData = {
                "property_token": state.property_token,
                "name": state.selected_event.name,
                "event_id": state.selected_event.productId,
                "event_tagged_id": state.selected_availability.eventId,
                "holding_id": state.booking_hold.id,
                "number_people": state.booking_information.numberPeople,
                "category": state.booking_information.category,
                "scheduled_for": state.selected_availability.startTime,
                "total_payment": state.booking_hold.totalPayable.amount,
                "total_taxes":state.booking_hold.price.totalTaxes.amount,
                "currency":state.booking_hold.totalPayable.currency,
                "booker": state.booking_creator,
                "guests": state.guest_list,
                'selected_language': state.booking_creator.preferred_language
            }
            if (state.property.configuration.validate_property_guest){
                requestData.room_number = state.hotel_guest.room_number
            }

            if (state.event_configuration.event_configuration !== undefined && state.event_configuration.event_configuration.property_configuration.slots) {
                requestData.collaborator_slots = {
                    collaborator_slot_id: state.event_configuration.event_configuration.collaborator_slot.id,
                    used_number: state.collaborator_used_slots
                };
            }

            // console.log(requestData)
            // here we call validations to ensure this is valid to send.
            const response = await axios.post(url,requestData,{
                headers:header
            })
            const data = response.data
            if (data.status){
                // here we perform any type of action required.
                commit('stagePaymentConfirmation', data)
                this.dispatch('resetBookingInfo');
                this.dispatch('changeDisplayLoader', false);
                return {status: true,
                        message: data.message}
            }
            else{
                this.dispatch('changeDisplayLoader', false);
                return {status: false,
                        message: data.error}
            }
            }
            catch(Error){
                this.dispatch('changeDisplayLoader', false);
                // console.log(Error)
                return {status: false,
                        message: Error.response.data.error}
            }
    },
    async fillHotelGuestInfo({commit}, dict_value){
        /*
            this is a generic
            method that fills all of the
            hotel guest data based on
            the given requirements
            and values.

            params in the objects needed:
            - key
            - value
        */
        commit('updateHotelGuestInfo',dict_value);
    },
    async fillBookerInfo({commit}, dict_value){
        /*
            this is a generic
            method that fills all of the
            booker data based on
            the given requirements
            and values.

            params in the objects needed:
            - key
            - value
        */
        commit('updateBookerInfo',dict_value);
    },
    async fillGuestInfo({commit}, dict_value){
        /*
            fills the guest information
            to based on the existing order.

            keys:
                order: index where to place
                the user.
                key: property to change
                value: value to update the value to.

                here we'll do the rendering.
        */
        commit('updateGuestInfo', dict_value);
    },
    async fillCardInfo({commit}, dict_value){
        /*
            fills the guest information
            to based on the existing order.

            keys:
                order: index where to place
                the user.
                key: property to change
                value: value to update the value to.

                here we'll do the rendering.
        */
        commit('updateCardInfo', dict_value);
    },
    validateRequired({commit}, payload){
        /*
        validates if the given
        value is truthy

        returns bool
        */
        var result = false
        var value = payload.value
        if(typeof(value)=='string'){
            let x = value.trim()
            result = x.length > 0
        }
        else{
            result = (payload.value)?true:false;
        }
        return result;
    },
    validateLessThan({commit}, payload){
        /*
        validates whether the value
        has less than the given amount of characters
        this is usually with strings.

        returns bool
        */
        var result = false
        let value = payload.value
        let limit = payload.limit
        console.log(payload)
        switch(value){
            case typeof(value) == 'string':{
                result = value.length < limit;
                break;
            }
            default:{
                result = value.toString().length < limit
                break;
            }
        }
        return result;
    },
    validateGreaterThan({commit}, payload){
        /*
        validates whether the value
        has less than the given amount of characters
        this is usually with strings.

        returns bool
        */
        var result = false
        let value = payload.value
        let limit = payload.limit
        switch(value){
            case typeof(value) == 'string':{
                result = value.length > limit;
                break;
            }
            default:{
                result = value.toString().length > limit
                break;
            }
        }
        return result;
    },
    validatePhoneNumber({commit}, payload){
        /*
        validates whether the value
        has less than the given amount of characters
        this is usually with strings.

        returns bool

        the limit value is the value
        where it mustn't pass aka the one used in
        the greater than
        */
        var result = false
        let value = payload.value
        let limit = payload.limit
        let val = value.toString().trim()
        switch(value){
            case typeof(value) == 'string':{
                let valu = value.toString().trim()
                result = valu.length >= 10 && valu.length <= limit;
                break;
            }
            default:{
                result = val.length >= 10 && val.length <= limit;
                break;
            }
        }
        return result;
    },
    validateEqual({commit}, payload){
        /*
        validates whether the value
        has equal length the given amount of characters
        this is usually with strings.

        returns bool
        */
        var result = false
        let value = payload.value
        let limit = payload.limit
        switch(value){
            case typeof(value) == 'string':{
                result = value.length == limit;
                break;
            }
            default:{
                result = value.toString().length == limit
                break;
            }
        }
        return result;
    },
    validateEmail({commit}, payload){
        /* validates if the given email has the proper format
          returns bool
        */
        var result = false
        let value = payload.value
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value))
        {
            result = true;
        }
        return result
    },
    validateCardExpirationDate({commit}, payload){
        /* validates if the given email has the proper format
          returns bool
        */
        var result = false
        var value = payload.value.toString()
        var first_eval = value.substring(0,2)
        var second_eval = value.substring(2,2)
        if(value.trim().length>0){
            if(parseInt(first_eval)<=12){
                result = true;
            }
        }
        return result
    },
    validateNumber({commit}, payload){
      /* validates if the given email has the proper format
        returns bool
      */
        var result = false
        let value = payload.value
        if (/^[0-9]*$/.test(value))
        {
            result = true;
        }
        return result
    },
    async changeValidationStatus({commit}, validation){
        // validates and changes
        // the value of the validation run.
        // returns None
        let validate = validation.v
        switch(validate.validation.parent){
            case 'BookingForm':{
                this.commit('UpdateBookingFormField', validation);
                break;
            }
            case 'cancelBooking':{
              this.commit('UpdateRefundValidators', validation);
              break;
            }
            default:{
                this.commit('UpdatePaymentField', validation);
                break;
            }
        }

    },

    async ValidateField({commit, state}, payload){
        /*
            validates the field
            with the given validators
            and updates the validation on
            vuex.

            here we also need to highlight the field
            itself.
            returns None.
        */
       var result = false;
       let value = payload.value
       var paid = {value:value}
       var validator = payload.validation

       for(let valid of validator.validators){
           switch(valid.validator){
               case 'email':{
                    // console.log('here email validating')
                    result = await this.dispatch('validateEmail', paid)
                    break;
               }
               case 'lessThan':{
                    var limix = valid.limit;
                    paid.limit = limix;
                    result = await this.dispatch('validateLessThan', paid)
                   break;
               }
               case 'greaterThan':{
                   var limix = valid.limit;
                   paid.limit = limix;
                   result = await this.dispatch('validateGreaterThan', paid)
                   break;
               }
               case 'phoneNumber':{
                   var limix = valid.limit;
                   paid.limit = limix;
                   result = await this.dispatch('validatePhoneNumber', paid)
                   break;
               }
               case 'equal':{
                var limix = valid.limit;
                // console.log(valid.limit, limix)
                paid.limit = limix;
                result = await this.dispatch('validateEqual', paid)
                break;
              }
              case 'expiration_card':{
                result = await this.dispatch('validateCardExpirationDate', paid)
                break;
              }
              case 'number':{
                result = await this.dispatch('validateNumber', paid)
                break;
              }
              default:{
                   // this is for required.
                    result = await this.dispatch('validateRequired', paid);
                    break;
               }
           }

        //   change state on vuex
        var validation_set = {valid: result,
                              v: payload}
        this.dispatch('changeValidationStatus',
                      validation_set);
        if (!result){
            break;
        }


       }
       return result;
    },
    // this method needs to be updated
    // for the new implementation
    async reValidateFields({commit, state},payload){

        switch(payload.parent){
            case 'BookingForm': {
             //    here we need to go throu
             //  all fields then the guests

             for(let field in payload.validator.booking_creator){
                 var sender = {value: state.booking_creator[field],
                               validation: payload.validator.booking_creator[field]
                               }
                this.dispatch('ValidateField', sender)
             }

            //  guests
            for(let vali of payload.validator.guests){
                for(let field in vali){
                    // console.log(field, vali)
                    var sender = {value: state.guest_list[vali[field].guest_position][field],
                                  validation: vali[field]
                                  }
                    this.dispatch('ValidateField', sender)
                }
            }

             break;
            }
            case 'PaymentForm': {
                for(let field in payload.validator){
                    if (field != 'validated'){
                        var sender = {value: state.card[field],
                            validation: payload.validator[field]
                            }
                        this.dispatch('ValidateField', sender)
                    }

                }
             break;
            }
            case 'HotelGuestRoom': {
                for(let field in payload.validator){
                    if (field != 'validated'){
                        var sender = {value: state.hotel_guest[field],
                            validation: payload.validator[field]
                            }
                        // console.log(sender, 'Guest validation')
                        this.dispatch('ValidateField', sender)
                    }

                }
             break;
            }
            case 'cancelBooking': {
              for(let field in payload.validator){
                  if (field != 'validated'){
                      var sender = {value: state.cancel_booking[field],
                          validation: payload.validator[field]
                          }
                      // console.log(sender, 'Guest validation')
                      this.dispatch('ValidateField', sender)
                  }

              }
           break;
          }
            default: {
                break;
            }
        }

    },

    async checkFieldsValidation({commit, state}, validation_object){
        // checks if all fields are validaded
        var final_result = false
        var validations_run = 0
        var validation_passed = 0
        for(let ob in validation_object){
            if(ob == 'booking_creator'){
                for (let v in validation_object[ob]){
                    validations_run += 1
                    // console.log('Booker',ob,validation_object[ob][v].validated, validation_object[ob][v])
                    if(validation_object[ob][v].validated){
                        validation_passed += 1
                    }
                }
            }
            else if(ob == 'guests'){
                for (let guest of validation_object[ob]){
                    for(let guest_field in guest){
                        if(guest_field == 'first_name' || guest_field == 'last_name'){
                            validations_run += 1
                            // console.log('guest', guest, guest_field,
                            //             guest[guest_field],
                            //             state.guest_list[guest[guest_field].guest_position].is_main)
                            if(guest[guest_field].validated || state.guest_list[guest[guest_field].guest_position].is_main)
                            {
                                validation_passed += 1
                            }
                        }

                    }
                }
            }
            else if(ob != 'validated'){
                // payment validation
                // console.log(validation_object[ob])
                // throw Error
                for (let v in validation_object[ob]){
                    validations_run += 1
                    if(validation_object[ob].validated){
                        validation_passed += 1
                    }
                }
            }
        }
        // console.log(validations_run, validation_passed)
        final_result = validations_run == validation_passed

        return {
            passed: final_result,
            error: "There are errors in your form, please correct them and try again."
        }
    },
    async runValidations({commit, state}, object_name){
        // validates all of the values stored in the
        // model setup based on existing validation
        // returns object.
        // var who_validator = 'BookingForm'
        var obj_validation = null;
        if(object_name == 'BookingForm'){
            obj_validation = state.validation_ruleset.BookingForm
        }
        else if(object_name == 'PaymentForm'){
            obj_validation = state.validation_ruleset.PaymentForm
        }
        else if(object_name=='HotelGuestRoom'){
            obj_validation = state.validation_ruleset.HotelGuestRoom
        }
        var result = await this.dispatch('checkFieldsValidation', obj_validation)
        if (!result.passed){
            this.dispatch('reValidateFields',
                   {parent: object_name,
                    validator: obj_validation})
        }
        return result
    },
    async completeBookingPayment({commit, state}){
        this.dispatch('changeDisplayLoader', true);
        try{
            var property_id = state.property_token
            let header = {'Authorization': 'Token '+property_id,
                        'Content-Type': 'application/json',
                        };
            let url = `${urls.complete_paid_booking}`
            let request_data = {
                card: state.card,
                booking_hold_id: state.booking_hold.id,
                booking: state.backed_booking.booking,
                isCollaborator: state.isCollaborator,
            }

            if (state.event_configuration.event_configuration && state.event_configuration.event_configuration.property_configuration.slots) {
                request_data.collaborator_slots = {
                    collaborator_slot_id: state.event_configuration.event_configuration.collaborator_slot.id,
                    used_number: state.collaborator_used_slots
                };
            }

            if (state.property.configuration.validate_property_guest){
                request_data.room_number = state.hotel_guest.room_number
            }

            const response = await axios.post(url,request_data,{
                headers:header
            })

            const data = response.data

            if (data.status){
                state.startHoldTimer = false;
                // here we perform any type of action required.
                commit('stagePaymentConfirmation', data)
                let pep_count = state.booking_information.numberPeople;

                state.guest_list.map((option, index) => {
                    const guest = data.booking.additional_guests.findIndex((guest) => guest.first_name.toLowerCase().trim() === option.first_name.toLowerCase().trim() && guest.last_name === option.last_name);

                    if(option.is_main){
                        state.guest_list[index].uuid = '';
                    }else if (guest > -1){
                        state.guest_list[index].first_name = '';
                        state.guest_list[index].last_name = '';
                        state.guest_list[index].uuid = data.booking.additional_guests[guest].uuid;
                    }
                });

                for (let i=0;i<pep_count;i++){
                    state.validation_ruleset.BookingForm.guests.push({
                        first_name: {
                            deno:'G',
                            field: 'first_name',
                            parent:'BookingForm',
                            validated: false,
                            showErrors: false,
                            validators: [{
                                error: 'this value is required',
                                validator: 'required',
                                order: 0
                            }],
                            guest_position: i
                        },
                        last_name: {
                            deno:'G',
                            field: 'last_name',
                            parent:'BookingForm',
                            validated: false,
                            showErrors: false,
                            validators: [{
                                error: 'this value is required',
                                validator: 'required',
                                order: 0
                            }],
                            guest_position: i
                        }
                    },)
                }
                // this.dispatch('resetBookingInfo');
                this.dispatch('changeDisplayLoader', false);
                return {status: true,
                        message: data.message}
            }
            else{
                this.dispatch('changeDisplayLoader', false);
                return {status: false,
                        message: data.error}
            }
        }
        catch(err){
            this.dispatch('changeDisplayLoader', false);
            return {status: false,
                    message: err.response.data.error}
        }
    },
    // needs modification based on needed
    async validateHotelGuest({commit, state}){
        /*
            validates the if the given
            values are stored in the
            hotel db and we can determine if
            the guest is actually who they say
            they are.

            :returns: dict
        */
            // return {status:true,
            //         message:'User Authenticated'}
            //  this needs to be modified so that it can be finished.
            this.dispatch('changeDisplayLoader', true);
            var error_message = 'The value provided is invalid'
            try{
                var property_id = state.property_token
            let header = {'Authorization': 'Token '+property_id,
                          'Content-Type': 'application/json',
                          };
            let url = `${urls.search_hotel_guest}`
            let request_data = {
                room_number: state.hotel_guest.room_number,
                last_name: state.hotel_guest.last_name,
                request_type: state.hotel_guest.request_type,
                isTortugaBay: state.isTortugaBay
            }
            const response = await axios.post(url,request_data,{
                headers:header
            })
            const data = response.data
            if (data.status){
                // here we perform any type of action required.
                // commit('stagePaymentConfirmation', data)
                // this.dispatch('resetBookingInfo');
                var returning_message = ""
                var take_status = true;
                returning_message = (data.message)?data.message: 'the user was authenticated'
                this.dispatch('changeDisplayLoader', false);
                return {status: take_status,
                        message: returning_message}
            }
            else{
                this.dispatch('changeDisplayLoader', false);
                // here we need to highlight the fields
                commit('UpdateHotelGuestValidatiors',
                       {validator:state.validation_ruleset.HotelGuestRoom.last_name,
                        error: error_message})
                commit('UpdateHotelGuestValidatiors',
                       {validator:state.validation_ruleset.HotelGuestRoom.room_number,
                        error: error_message})
                // state.validation_ruleset.HotelGuestRoom.last_name.validated=false;
                // state.validation_ruleset.HotelGuestRoom.last_name.showErrors=true;
                // state.validation_ruleset.HotelGuestRoom.room_number.validated=false;
                // state.validation_ruleset.HotelGuestRoom.room_number.showErrors=true;

                return {status: false,
                        message: data.error}
            }
            }
        catch (Error){
            this.dispatch('changeDisplayLoader', false);
            commit('UpdateHotelGuestValidatiors',
                   {validator:state.validation_ruleset.HotelGuestRoom.last_name,
                    error: error_message})
            commit('UpdateHotelGuestValidatiors',
                   {validator:state.validation_ruleset.HotelGuestRoom.room_number,
                    error: error_message})

            return {status: false,
                message: "The room provided is not assigned under the given lastname, please try again."}
        }

    },
    holdTimerConfig({commit, state}, value){
        state.startHoldTimer = value;
    },
    async createFakeBookingGuest({commit, state}, bookerName){
        state.guest_list.map((option, index) => {
            const number = index === 0 ? 1 : index;
            option.first_name = `${bookerName}'s - Participant`
            option.last_name = `#${number}`
        });
    },
    async fillRefundBookingForm({commit, state}, field_data){
      /*
        fills the data for the form
      */
      commit('fillRefundForm', field_data);

    },
    async ValidateBookingCancellation({commit, state}, payload){
      /*
        makes the validation of the given links
        to check whether the form can be shown
        or not.
      */

       this.dispatch('changeDisplayLoader', true);
       let property_id = payload.user_token;
       let header = {'Authorization': 'Token '+property_id,
            'Content-Type':'application/json',
            };
       let url = `${urls.cancel_booking}${payload.cancel_hash}/`
       const response = await axios.get(url, {headers:header, mode:'cors'});
       let response_data = response.data;
       commit('setRedirectUrl', response_data);
       this.dispatch('changeDisplayLoader', false);
       return response_data
    },
    async setRefundErrors({commit}, payload){
      /*
        sets the errors for the invalid refund.
      */
        commit('setErrorsRefund', payload);
    },
    async cleanRefundFields({commit, state}){
      /*
        cleans the data from the model fields.
      */
      commit('clenrefundFields');
      commit('refundCleanErrors');
    },
    async cleanRefundErrors({commit}){
      /*
        removes the errors for the invalid refund.
      */
      commit('refundCleanErrors');
    },

    async sendBookingRefund({commit, state}, payload){
      /*
        sends the refund
        for the given user.
        if valid, it processes
        it and return to success
        message page.
      */
      this.dispatch('changeDisplayLoader', true);
      let property_id = payload.user_token;
      let header = {'Authorization': 'Token '+property_id,
            'Content-Type':'application/json',
            };
      let url = `${urls.cancel_booking}${payload.cancel_hash}/`
      let request_body = {
        last_4_digit_card: state.cancel_booking.last_4_digit_card,
        confirmation_payment_code: state.cancel_booking.confirmation_payment_code
       };
       const response = await axios.post(url,request_body,
                                         {headers: header});
       let response_data = response.data;
       if (response_data.status){
        // remove errors and clean states
        console.log(response_data)
       }
       this.dispatch('changeDisplayLoader', false);
       return response_data;
    },
    async setSuccessRefundMessage({commit}, payload){
      /*
        sets the booking success message
        on the component to be implemented.
      */
      commit('successSetRefundMessage', payload);
    },
    async finishRefundProcess({commit}, payload){
      /*
        finishes the whole process of refunding.
        this is called when you cancel or when
        hit okay after the successful refund.
      */
      let redirect_to = payload.redirect_url;
      this.dispatch('changeDisplayLoader', true);
      await this.dispatch('cleanRefundFields');
      this.dispatch('changeDisplayLoader', false);
      await commit('resetState');
      window.location = redirect_to;

    }

};

const mutations = {
    setCollaborator: (state, value) => {
        state.collaborator = value;
    },
    changeDisplayingLoader: (state, value) =>{
        // state.show_loader = value;
        state.show_loader = value;
    },
    changeDisplayMainHeader: (state, value) =>{
        state.property_loaded = value;
    },
    loadInitialList: (state, response_data) =>{
        state.property = response_data.property;

        if (state.property.name === 'Tortuga Bay') {
            state.isTortugaBay = true;
        } else {
            state.isTortugaBay = false;
        }

        state.event_list = response_data.available_events.data;
        state.property_token = response_data.property_token;
        state.finished = true;
        state.property_loaded = true;
    },
    loadSelectedEvent: (state, event) => {
        state.selected_event = event;
        // here we need to set the initial
        // dates for the search.
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        state.booking_information.start_time=firstDay;
        state.booking_information.end_time=lastDay;

    },
    increaseAmountGuests: (state) =>{
        state.booking_information.numberPeople += 1;
    },
    decreaseAmountGuests: (state) =>{
        state.booking_information.numberPeople -= 1;
    },
    resetBookerInfo: (state)=>{
        state.booking_creator = {
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            username: '',
        }
        state.guest_list = []
    },
    resetBookingDefault: (state) => {
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        state.startHoldTimer = false;
        state.booking_information = {
            numberPeople: 1,
            category:'Cadults',
            start_time:firstDay,
            end_time:lastDay,
            price: ""
        };
        state.selected_date = date.toJSON().replace('Z','')
        state.day_availability_spots = []
        state.available_spots_calendar = []
        state.booking_creator = getBookingCreator()
        state.guest_list = []
        state.formated_calendar_dates = []
        state.indexable_available_dates ={}
        state.booking_hold = {},
        state.booking_hold_request = {},
        state.selected_availability = {}
        state.accepted_terms = false
        state.myself_included = true
        state.hotel_guest={
            room_number: "",
            last_name: "",
            request_type: {},
            isTortugaBay: false,
        }
        state.card = {
            number: "",
            cvc: "",
            name: "",
            expiration: "",
        }
        // state.collaborator = {};
        // state.isCollaborator = false;
        state.event_configuration = {};
        state.validation_ruleset=getValidationSet()
    },
    resetState: (state)=>{
        state = getDefaultState();
    },
    resetSelectedDate: (state, month_year)=>{
        if (state.selected_date)
        {
            let prior_date = state.selected_date
            let new_date = new Date(prior_date);
            new_date.setMonth(month_year.month-1)
            // console.log(new_date, response_data.month_year)
            state.selected_date = new_date.toJSON().replace('Z','');
        }
    },
    loadAvailableDates: (state, response_data)=>{

        state.available_spots_calendar = response_data.data.response.data
        var dates = []
        var index = {}
        for (let spot of state.available_spots_calendar){
            var d = new Date(spot.startTime)
            dates.push(d)
            index[spot.startTime] = spot
        }
        state.formated_calendar_dates = dates;
        state.indexable_available_dates = index;
    },
    setDate: (state, date_to_select) =>{
        if (!date_to_select){
            date_to_select = new Date().toJSON().replace('Z','')
        }
        // console.log(date_to_select)
        state.selected_date = date_to_select;
        let modifiable_date = new Date(date_to_select);
        modifiable_date.setHours(0, 0, 0)
        state.booking_information.start_time = modifiable_date.toJSON().replace('Z','')
        state.booking_information.end_time = modifiable_date.toJSON().replace('Z','')
    },
    loadAvailableSpots: (state, response_data)=>{
        let available_events = response_data.data.response.data
        for (let event of available_events){
            event.formated_start_time = new Date(event.startTime).toLocaleTimeString('en-DO', { timeZone: 'America/Santo_Domingo' });
            if (state.isCollaborator) {
                const totalParticipant = state.collaborator_included === true ? state.booking_information.numberPeople - 1 : state.booking_information.numberPeople;
                if(state.event_configuration.event_configuration.property_configuration.slots && state.event_configuration.event_configuration.property_configuration.guest) {
                    if(state.event_configuration.event_configuration.collaborator_slot.available_slots > 0 && state.collaborator_included) {
                        event.price.amount = 0;
                        const extra_participants = totalParticipant > state.event_configuration.event_configuration.guest_configuration.total_guest ?
                        totalParticipant - state.event_configuration.event_configuration.guest_configuration.total_guest : 0;

                        if (extra_participants > 0) {
                            event.price.amount = (state.selected_event.defaultRates[0].price.amount * extra_participants)
                        }
                        event.price.amount += state.collaborator_included === true ?
                        state.event_configuration.event_configuration.guest_configuration.Price * (totalParticipant - extra_participants):
                        state.event_configuration.event_configuration.guest_configuration.Price * (totalParticipant- extra_participants);
                        state.collaborator_used_slots = 1;
                    }
                } else if (state.event_configuration.event_configuration.property_configuration.slots) {
                    event.price.amount = 0;
                    let standarParticipants = Math.abs(totalParticipant - state.event_configuration.event_configuration.collaborator_slot.available_slots);

                    if (totalParticipant <= state.event_configuration.event_configuration.collaborator_slot.available_slots) {
                        standarParticipants = 0;
                    }

                    event.price.amount += standarParticipants * state.selected_event.defaultRates[0].price.amount;

                    const slotsParticipants = Math.abs(standarParticipants - totalParticipant);

                    if (slotsParticipants > 0) {
                        state.collaborator_used_slots = slotsParticipants;
                        event.price.amount += (slotsParticipants * state.event_configuration.event_configuration.slots_configuration.price)
                    }

                } else if (state.event_configuration.event_configuration.property_configuration.guest) { // Is Guest
                    event.price.amount = 0;
                    const extra_participants = totalParticipant > state.event_configuration.event_configuration.guest_configuration.total_guest ?
                    totalParticipant - state.event_configuration.event_configuration.guest_configuration.total_guest : 0;

                    if (extra_participants > 0) {
                        event.price.amount = (state.selected_event.defaultRates[0].price.amount * extra_participants)
                    }

                    event.price.amount += state.collaborator_included === true ?
                    state.event_configuration.event_configuration.guest_configuration.Price * (totalParticipant - extra_participants):
                    state.event_configuration.event_configuration.guest_configuration.Price * (totalParticipant- extra_participants);
                }

            }

            event.formated_price = new Intl.NumberFormat('en-US',
                                                         { style: 'currency',
                                                           currency: event.price.currency}
                                                        ).format(event.price.amount)
            if(state.indexable_available_dates[event.startTime]){
                // console.log(state.indexable_available_dates[event.startTime])
                event.available_spots = state.indexable_available_dates[event.startTime].numSeatsAvailable
            }
            else{
                event.available_spots = 0
            }
        }
        state.day_availability_spots = available_events;
    },
    setBookingHold:(state, response_data)=>{
        state.booking_hold = response_data.hold;
        state.selected_availability = response_data.spot;
        // here we need to create the X amount of people
        // needed to gather the data and apply it
        // to the list
        var start_time = new Date(response_data.spot.startTime)
        var end_time = new Date(response_data.spot.endTime)
        state.booking_information.start_time = start_time.toJSON().replace('Z','')
        state.booking_information.end_time = end_time.toJSON().replace('Z','')
        state.selected_date = start_time.toJSON().replace('Z','')
        let pep_count = state.booking_information.numberPeople;
        state.guest_list = [];
        for (let i=0;i<pep_count;i++){
            state.guest_list.push({first_name: '',
                                   last_name: '',
                                   position: i,
                                   is_main: i==0})
        }
    },
    acceptTerms: (state, value) =>{
        state.accepted_terms = value;
    },
    changeIsMain: (state, value) =>{
        /*
            changes the guest numeration based on the given
            which means sets the given object
            true and the rest false.
            if all are false it's okay
            but there must be only one
            as true.
        */
       let guest = value.g;
       for(let g of state.guest_list){
            if (g.position == guest.position){
                if (!g.is_main && value.is_main){
                    g.first_name = state.booking_creator.first_name
                    g.last_name = state.booking_creator.last_name
                }
                else if(g.is_main && !value.is_main){
                    g.first_name = ""
                    g.last_name = ""
                }
                g.is_main = value.is_main;

            }
            else if(value.is_main && g.is_main){
                g.is_main = false;
            }
       }
    },
    cancelingBooking: (state)=>{
    },
    stageBackedBooking: (state, response_data) =>{
        state.backed_booking = response_data;
    },
    updateHotelGuestInfo: (state, dict_data) =>{
        /*
            mutates the hotel guest
            so that it could be validated
        */
        state.hotel_guest[dict_data.key] = dict_data.val;
    },
    updateBookerInfo: (state, dict_data) =>{
        /*
            mutates the main booker
            booking_creator: {
            name: '',
            last_name: '',
            email: '',
            phone_number: '',
            birthday: ''
        },
        */
        state.booking_creator[dict_data.key] = dict_data.val;
        if(dict_data.key == 'email'){
            state.booking_creator.username = dict_data.val;
        }
        for(let g of state.guest_list){
            if (g.is_main){
                g.first_name = state.booking_creator.first_name
                g.last_name = state.booking_creator.last_name
                break;
            }

       }
    },
    updateGuestInfo: (state, dict_data) => {
        let guest = dict_data.guest;
       for(let g of state.guest_list){
            if (g.position == guest.position){
                g[dict_data.key]=dict_data.val
                break;
            }

       }
    },
    updateCardInfo:(state, dict_data) => {
        state.card[dict_data.key] = dict_data.value;
    },
    stagePaymentConfirmation: (state, data) => {
        state.payment_confirmation = data;
    },
    UpdateBookingFormField: (state, validation_set) =>{
        var value = validation_set.valid
        var validator = validation_set.v.validation

        if (validator.deno == 'B'){
            state.validation_ruleset[validator.parent].booking_creator[validator.field].validated=value
            state.validation_ruleset[validator.parent].booking_creator[validator.field].showErrors=!value
            // state.validation_ruleset[validator.parent][validator.field].showErrors=!value
        }
        else{
            // guests
            state.validation_ruleset[validator.parent].guests[validator.guest_position][validator.field].validated=value
            state.validation_ruleset[validator.parent].guests[validator.guest_position][validator.field].showErrors=!value
        }
    },
    UpdatePaymentField: (state, validation_set) =>{
        var value = validation_set.valid
        var validator = validation_set.v.validation
        // console.log(validation_set, validator)
        state.validation_ruleset[validator.parent][validator.field].validated=value
        state.validation_ruleset[validator.parent][validator.field].showErrors=!value
    },
    UpdateHotelGuestValidatiors: (state, payload)=>{
        /*
            updates the validators
            on the and shows the errors
            based on the given required
        */
        var validator = payload.validator
        state.validation_ruleset[validator.parent][validator.field].validated=true
        state.validation_ruleset[validator.parent][validator.field].showErrors=true
        state.validation_ruleset[validator.parent][validator.field].validators[0].error = payload.error
        // {
        //     error: 'this value is required',
        //     validator: 'required',
        //     order: 0
        // }
    },
    loadCustomerLanguages: (state, languages) =>{
      state.languages_available = languages;
    },
    setBookerLanguage: (state, language) => {
      state.sel_language = language.label;
      state.booking_creator.preferred_language=language.code;
    },

    fillRefundForm: (state, field_data) =>{
      // updates the field for the cancellation
      state.cancel_booking[field_data.key] = field_data.val;
      state.send_refund =(state.validation_ruleset.cancelBooking.last_4_digit_card.validated &&
        state.validation_ruleset.cancelBooking.confirmation_payment_code.validated)
    },

    UpdateRefundValidators: (state, validation_set) =>{
      // updates the validation for the booking
      // cancellation.
      var value = validation_set.valid
      var validator = validation_set.v.validation
      state.validation_ruleset[validator.parent][validator.field].validated=value
      state.validation_ruleset[validator.parent][validator.field].showErrors=!value
    },
    setErrorsRefund: (state, payload) => {
      state.cancellation_error_message = payload.error
      state.cancellation_error_code = 400
      state.show_errors_cancellation = true
    },
    refundCleanErrors: (state) => {
      state.cancellation_error_message = ''
      state.cancellation_error_code = ''
      state.show_errors_cancellation =false
      state.send_refund = false;
    },
    clenrefundFields: (state) => {
      state.cancel_booking = getCancelBookingForm();
    },
    setRedirectUrl: (state, payload) =>{
      state.redirect_url = payload.redirect_url;
    },
    successSetRefundMessage: (state, payload) => {
      state.sucessRefund = payload.confirmation;
    }

};
export default{
    state,
    getters,
    actions,
    mutations
}
