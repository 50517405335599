<template>
  <div class="payment_form_container">
    <h3 class="form_title title_liner">
        Card Details
    </h3>
    <div class="row">
        <div class="col-md-6 primary_col">
            <Input input_type="text" label_title='Name on card' v-model="nameOnCard"
            :validation="ruleset_validation.PaymentForm.name"
            html_unique="name_on_card"
            :required_f='false'
            />
            <Input input_type="tel" label_title='Card' v-model="cardNumber"
             :validation="ruleset_validation.PaymentForm.number"
             masked="9999-9999-9999-9999"
             html_unique="form_number"
             :required_f='false'
             :is_bank_card='true'
             />

            <div class="row">
                <div class="col-md-6">
                    <Input input_type="tel" label_title='MM/YY' v-model="expirationDate"
                       :validation="ruleset_validation.PaymentForm.expiration"
                       masked='99/99'
                       html_unique="expiration"
                       :required_f='false'
                       />
                </div>
                <div class="col-md-6">
                       <Input input_type="tel" label_title='CVV' v-model="Cvv"
                        :validation="ruleset_validation.PaymentForm.cvc"
                        html_unique="cvv"
                        :required_f='false'
                        />
                </div>
            </div>

        </div>
    </div>
  </div>

</template>

<script>
import Input from './Input.vue';
import {mapActions,mapGetters} from 'vuex';
export default {
name:'PaymentFormBody',
components:{
    Input
},
computed:{
        ...mapGetters(['card_getter','ruleset_validation']),
        cardNumber: {
            get() {
                return this.card_getter.number
            },
            set(value){
                let sender = {
                    key:'number',
                    value: value
                }
                console.log(value)
                this.$store.dispatch('fillCardInfo', sender)
            }
        },
        nameOnCard: {
            get() {
                return this.card_getter.name
            },
            set(value){
                let sender = {
                    key:'name',
                    value: value
                }
                this.$store.dispatch('fillCardInfo', sender)
            }
        },
        Cvv: {
            get() {
                return this.card_getter.cvc
            },
            set(value){
                let sender = {
                    key:'cvc',
                    value: value
                }
                this.$store.dispatch('fillCardInfo', sender)
            }
        },
        expirationDate: {
            get() {
                return this.card_getter.expiration
            },
            set(value){
                let sender = {
                    key:'expiration',
                    value: value
                }
                this.$store.dispatch('fillCardInfo', sender)
            }
        },
    },
methods:{
    ...mapActions(['fillCardInfo'])
}
}
</script>

<style scoped>
.title_liner{
    margin-left: 5px;
}
/* .payment_form_container{
    max-width: 100%;
} */
</style>
