import { createApp } from 'vue'
import App from './App.vue'
import router from './routes'
import store from './store'
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import * as Sentry from "@sentry/vue";
const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://062b98e794c96cee33eec70ec182fe72@o4504163159441408.ingest.sentry.io/4506218599022592",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});



app.use(store);
app.use(router);
app.use(PrimeVue);
app.use(ToastService);
app.mount('#app');
