<template>
<div v-cloak class="full_app_container">
  <div class="container">
    <div v-show="holdTimer" class="timer-container">
          <Timer />
    </div>
    <div class="header_container">
      <PropertyHeader/>
    </div>
    <Toast position="top-right" />
    <Loader />  
    <router-view>
    </router-view>
  </div>
    <ScrollTop :threshold="200" />
    <Footer />
</div>
 
</template>
<script>
import Footer from './components/Footer.vue';
import PropertyHeader from './components/PropertyHeader.vue';
import {mapGetters, mapActions} from 'vuex';
import Toast from 'primevue/toast';
import Timer from './components/Timer.vue';

import Loader from './components/Loader.vue';
import ScrollTop from 'primevue/scrolltop';

export default {
  name: 'App',
  components: {
    Footer,
    PropertyHeader,
    Toast,
    Loader,
    ScrollTop,
    Timer
  },
  computed: mapGetters(['finished_loading', 'holdTimer']),
  methods: {
    ...mapActions(['getEventList'])
  },
}
</script>

<style>
  .generic_floating_container {
      background: rgba( 255, 255, 255, 0.1 );
      box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
      border-radius: 5px;
      border: 1px solid rgba( 255, 255, 255, 0.18 );
      padding: 10px;
      margin-bottom: 8px;
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }

  .timer-container {
    position: sticky;
    top: 0; /* Adjust this value as needed */
  }

  @media only screen  and (max-width: 700px){
    .header_container{
      text-align: left;
    }
  }
</style>
