/* set the list of items that will be used in order to select the values for the booking */ 

<template>
  <div class="container main_container">
      <div class="selected_date">
          <h3 class="selected_date card-title">
              {{new Date(date_selected).toDateString()}}
          </h3>
      </div>
      <div class="row events_container">
          <div class="list_renderer" v-if="availability_list.length>0">
                <AvailableEvent v-for="(event, index) in availability_list" :key="index" :availability="event"/>
          </div>
          <div class="not_items_container" v-else>
              <h3>
                  No spots available
              </h3>
          </div>
      </div>

  </div>
</template>

<script>
import AvailableEvent from './AvailableEvent.vue';
import {mapActions, mapGetters} from 'vuex';
export default {
    name: "AvailableEventList",
    components:{
        AvailableEvent
    },
    computed:mapGetters(['availability_list','date_selected']),
}
</script>

<style scoped>
    .events_container{
        /* overflow-y: scroll; */
        scroll-behavior: smooth;
        overflow: scroll;
        max-height: 250px;
        margin: 11px 10px 0px 21px;
    }
    .main_container{
        margin-top:20px;
    }
    .selected_date{
        color:green;
    }
</style>