// this is the template to take care of the 
// of the existing event based on the data.

<template>
  <div class="row" v-cloak>
      <div class="col-md-5">
          <!-- date and number of sits available -->
          <h5>
            {{availability.formated_start_time}}
          </h5>
          <p v-if="index_available[availability.startTime]">
             Available {{index_available[availability.startTime].numSeatsAvailable}}
          </p>
         
      </div>
      <div class="col-md-3">
        <!-- price -->
        USD{{ availability.formated_price }}
      </div>

      <div class="col-md-4">
          <!-- here we need to determine whether this is a free booking or not. -->
          <Button title='Book' render_class="btn btn-success form-control booking_btn" @click="proceedBooking"/>
      </div>
  </div>
</template>

<script>
import Button from './Button.vue'
import {mapGetters, mapActions} from 'vuex'
import {moment} from 'moment'
export default {
    name:'AvailableEvent',
    props:{
        availability:{
            type: Object
        }
    },
    computed:mapGetters(['index_available', 'prop_token', 'event_selected', 'isCollaborator']),
    components:{
        Button
    },
    methods:{
        ...mapActions(['createBookingHold']),
        proceedBooking(){
            // console.log(this.availability)
            this.$store.dispatch('createBookingHold', this.availability).then(
                (response)=>{
                    if(response.status){
                        // here we redirect
                        this.$toast.add({severity:'success',
                                         summary:'Booking Selected',
                                         detail: response.message,
                                         life:7000})
                        this.$store.dispatch('holdTimerConfig', true).then(() => {
                            this.$router.push({name:'booking_form',
                                           params: {event_id: this.event_selected.productId,
                                                    prop_id: this.prop_token}})
                        });
                        
                    }

                    else{
                        // here we render error.
                        this.$toast.add({severity:'error',
                                         summary:'Error',
                                         detail: response.message,
                                         life:7000})
                    }
                }
            ).catch(
                (error_response)=>{
                    this.$toast.add({severity:'error',
                                         summary:'Error',
                                         detail: error_response,
                                         life:7000})
                }
            )

        },
    }
}
</script>

<style scoped>
    [v-cloak]{
        display:none;
    }
    .booking_btn{
        margin-top:1px;
    }
</style>